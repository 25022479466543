import { AnalyticsBrowser } from '@segment/analytics-next';

function getKey() {
	if (typeof window === 'undefined') {
		return process.env.SEGMENT_WRITE_KEY;
	}

	return window.ENV.SEGMENT_WRITE_KEY;
}

export const analytics = AnalyticsBrowser.load({
	writeKey: getKey(),
});
